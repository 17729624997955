import { HeightLimitProps } from './interfaces';
import { Carousel } from '../../../index';
import React, { useEffect, useRef, useState } from 'react';

export default function HeightLimitCarousel(props: HeightLimitProps) {
	const [maxHeight, setMaxHeight] = useState<number | null>(null);
	const currentSlideRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (maxHeight !== currentSlideRef.current?.offsetHeight) {
			setMaxHeight(currentSlideRef.current?.offsetHeight || null);
		}
	}, [currentSlideRef.current, props.currentIndex]);

	useEffect(() => {
		window.dispatchEvent(
			new CustomEvent('notify-scrollable-height-change')
		);
	}, [maxHeight]);

	return (
		<Carousel {...props} fitContent={true} className={'height-limit'}>
			{
				props.children.map((child, index) => (
					<div
						className='slide-container'
						key={props.keyName + '-' + index}
						ref={
							index === props.currentIndex
								? currentSlideRef
								: undefined
						}
						style={
							index !== props.currentIndex && maxHeight
								? { maxHeight: maxHeight }
								: {}
						}
					>
						{child}
					</div>
				)) as Array<JSX.Element>
			}
		</Carousel>
	);
}
