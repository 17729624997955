import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { getSetup } from './getSetup';

const setup = getSetup();
const showMinimalEquipmentPopups = setup?.market?.showMinimalEquipmentPopups;
export const showInfoIcon = (item: SSPContentVO) =>
	(showMinimalEquipmentPopups &&
		item.asModel
			.reflist('packageItems')
			.filter(item => item.asModel.bool('marketable', true)).length >
			0) ||
	item.asModel.reflist('assets').length > 0 ||
	item.asModel.str('description', '').length > 0 ||
	item.asModel.str('benefits', '').length > 0 ||
	item.asModel.str('teaserText', '').length > 0 ||
	item.asModel.str('remarks', '').length > 0 ||
	item.asModel.str('details', '').length > 0;
