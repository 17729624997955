export async function registerSW(
	password: string,
	brand: string,
	cacheUrlsForBrand: Array<string>
) {
	if ('serviceWorker' in navigator) {
		const registrations = await navigator.serviceWorker.getRegistrations();
		let serviceWorker: ServiceWorker | null;
		if (registrations.length > 0) {
			serviceWorker = registrations[0].active;
			console.log('service worker found', serviceWorker);
		} else {
			try {
				serviceWorker = (
					await navigator.serviceWorker.register(
						'../serviceWorker.js',
						{
							scope: '/',
						}
					)
				).installing;
				console.log('service worker registered', serviceWorker);
			} catch (err) {
				serviceWorker = null;
				console.log('service worker registration failed', err);
			}
		}

		navigator.serviceWorker.addEventListener('message', event => {
			console.log('sw-cache-ready', event.data);
			localStorage.setItem(
				'sw-cache-ready' +
					(event.data.brand ? `-${event.data.brand}` : ''),
				event.data.msg
			);
		});

		if (serviceWorker) {
			serviceWorker.postMessage({
				type: 'CACHE_URLS',
				cacheEnding: `-${brand}`,
				brand: brand,
				payload: new Set(
					[
						...performance
							.getEntriesByType('resource')
							.map(r =>
								r.name.replace(window.location.origin, '')
							)
							.filter(name =>
								name.includes(window.location.origin)
							),
						...cacheUrlsForBrand,
					].map(url => url.replace(window.location.origin, ''))
				),
			});
			serviceWorker.postMessage({
				type: 'AUTH',
				payload: window.btoa(`ssp:${password}`),
			});
		}
	}
}
