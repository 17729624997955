import DOMPurify from 'dompurify';

// checks if the html is correct and save
export function checkHtml(html: string, clickMe?: string): { __html: string } {
	// do NOT allow script tags in html
	if (html.includes('<script>')) return { __html: '' };

	// do NOT allow links in html, replace with span
	if (html.includes('<a')) {
		const parser = new DOMParser();
		html.match(/<a\b[^>]*>(.*?)<\/a>/gi)?.forEach(match => {
			var t = document.createElement('template');
			t.innerHTML = DOMPurify.sanitize(match);
			const aTag = t.content.childNodes[0] as HTMLAnchorElement;
			html = html.replace(
				match,
				aTag.href
					? `<span class="clickable-qr-link">${
							clickMe && clickMe !== 'ClickMe'
								? clickMe
								: aTag.innerText
						}<span style='display: none'>${aTag.href}</span></span>`
					: `<span>${aTag.innerText}</span>`
			);
		});
	}

	return { __html: DOMPurify.sanitize(html) };
}
