import React from 'react';
import VideoPlayer from '../../did-utility/src/Components/VideoPlayer';
import './styles.scss';
import { VideoPlayerBrandProps } from '../../did-utility/src/Components/VideoPlayer/interfaces';

export default function VideoPlayerAudi(props: VideoPlayerBrandProps) {
	return (
		<VideoPlayer
			{...props}
			play={
				<svg>
					<use xlinkHref='#play-circle' />
				</svg>
			}
			pause={
				<svg>
					<use xlinkHref='#pause' />
				</svg>
			}
			volumeOn={
				<svg>
					<use xlinkHref='#audio-on' />
				</svg>
			}
			volumeOff={
				<svg>
					<use xlinkHref='#audio-off' />
				</svg>
			}
		/>
	);
}
