import React from 'react';
import { TeaserImageProps } from './inferfaces';
import './styles.scss';

export function TeaserImage(props: TeaserImageProps) {
	if (!props.equipItem.asModel.has('assets')) {
		return <div className='teaser-image no-image'>{props.altContent}</div>;
	}
	const asset = props.equipItem.asModel.reflist('assets')[0];
	const video = asset.contentType === 'assetVideo';

	const equipItem = props.equipItem;
	let tire: boolean;
	tire =
		equipItem.asModel.target.family === 'REI' ||
		equipItem.asModel.target.family === 'ZBR' ||
		equipItem.asModel.target.family === 'RAD';

	return (
		<div
			className={`teaser-image${video ? ' video' : ''} ${
				tire ? 'tire' : ''
			}`}
		>
			<img
				src={
					video
						? (asset.asAsset.posterUrl ??
							asset.asAsset.thumbUrl(1920, 1080))
						: asset.asAsset.url
				}
				alt={asset.asAsset.fileProperties.originalFilename}
			/>
			{video && (
				<svg className='icon-48'>
					<use xlinkHref={props.iconVideo} />
				</svg>
			)}
		</div>
	);
}
