import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { NotifyScrollableProps } from './interfaces';

export default function NotifyScrollable(props: NotifyScrollableProps) {
    const noAnimation = false;
    // new URLSearchParams(window.location.search).get('apk') === 'true';
    const componentRef = useRef<HTMLDivElement>(null);
    const notificationRef = useRef<HTMLDivElement>(null);

    useEffect(() => handleNotify(true, false), [props.deps]);

    useEffect(() => handleNotify(), []);

    window.addEventListener('notify-scrollable-height-change', () =>
        setTimeout(handleNotify, 1)
    );

    window.addEventListener('reset', () => handleNotify(true, true));

    function handleNotify(scroll?: true, smooth?: boolean) {
        if (isScrollable(componentRef.current)) {
            notificationRef.current?.classList.add('active');
            componentRef.current?.classList.add('scrollable');
            if (scroll)
                componentRef.current?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior:
                        smooth &&
                        new URLSearchParams(window.location.search).get(
                            'prefer-reduced-motion'
                        ) !== 'true'
                            ? 'smooth'
                            : undefined,
                });
        } else {
            notificationRef.current?.classList.remove('active');
            componentRef.current?.classList.remove('scrollable');
        }
    }

    function isScrollable(htmlElement: HTMLElement | null) {
        if (htmlElement !== null) {
            const hasScrollableContent =
                htmlElement.scrollHeight > htmlElement.clientHeight;
            const overflowYStyle =
                window.getComputedStyle(htmlElement).overflowY;
            const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

            return hasScrollableContent && !isOverflowHidden;
        }
        return false;
    }

    return (
        <div
            className={props.className}
            id={props.id}
            ref={componentRef}
            onScroll={e => {
                if (e.currentTarget.scrollTop > 0) {
                    notificationRef.current?.classList.remove('active');
                }
                if (props.onScroll) props.onScroll(e);
            }}
        >
            {props.children}
            {!props.dontShow && (
                <div
                    className={`notify-scrollable-notification${
                        noAnimation ? ' no-animation' : ''
                    }`}
                    ref={notificationRef}
                >
                    <svg>
                        <use xlinkHref='#chevron-xsmall' />
                    </svg>
                    <svg>
                        <use xlinkHref='#chevron-xsmall' />
                    </svg>
                    <svg>
                        <use xlinkHref='#chevron-xsmall' />
                    </svg>
                </div>
            )}
        </div>
    );
}
