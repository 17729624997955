import { getNumberString } from './getNumberString';
import i18next from 'i18next';
import SSPContentFieldAccessor from 'sspcontentext/src/model/accessor/SSPContentFieldAccessor';
import { getSetup } from './getSetup';

export type ConsumptionElementType = {
	key: string;
	headline: string;
	unit: string;
	digits?: number;
	round?: true;
	disclaimer?: string;
	efficiency?: true;
	hideIfEmpty?: true;
	overwrite?: number;
};

export type ConsumptionListType = {
	Electric: Array<ConsumptionElementType>;
	HybridPetrol: Array<ConsumptionElementType>;
	Diesel: Array<ConsumptionElementType>;
	Petrol: Array<ConsumptionElementType>;
	Gas: Array<ConsumptionElementType>;
};

const setup = getSetup();

const consumptionListMexico = {
	wltp: {
		Electric: [
			{
				key: 'CityEmissions',
				headline: 'MexicoCityEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
				overwrite: 0,
			},
			{
				key: 'HighwayEmissions',
				headline: 'MexicoHighwayEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
				overwrite: 0,
			},
			{
				key: 'ObservedCombinedCO2Emissions',
				headline: 'MexicoObservedCombinedCO2Emissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
				overwrite: 0,
			},
			{
				key: 'CityFuelEfficiency',
				headline: 'MexicoCityFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
				overwrite: 0,
			},
			{
				key: 'HighwayFuelEfficiency',
				headline: 'MexicoHighwayFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
				overwrite: 0,
			},
			{
				key: 'ObservedCombinedEfficiency',
				headline: 'MexicoObservedCombinedEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
				overwrite: 0,
			},
		],
		HybridPetrol: [
			{
				key: 'CityEmissions',
				headline: 'MexicoCityEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'HighwayEmissions',
				headline: 'MexicoHighwayEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedCO2Emissions',
				headline: 'MexicoObservedCombinedCO2Emissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'CityFuelEfficiency',
				headline: 'MexicoCityFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'HighwayFuelEfficiency',
				headline: 'MexicoHighwayFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedEfficiency',
				headline: 'MexicoObservedCombinedEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
		],
		Diesel: [
			{
				key: 'CityEmissions',
				headline: 'MexicoCityEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'HighwayEmissions',
				headline: 'MexicoHighwayEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedCO2Emissions',
				headline: 'MexicoObservedCombinedCO2Emissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'CityFuelEfficiency',
				headline: 'MexicoCityFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'HighwayFuelEfficiency',
				headline: 'MexicoHighwayFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedEfficiency',
				headline: 'MexicoObservedCombinedEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
		],
		Petrol: [
			{
				key: 'CityEmissions',
				headline: 'MexicoCityEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'HighwayEmissions',
				headline: 'MexicoHighwayEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedCO2Emissions',
				headline: 'MexicoObservedCombinedCO2Emissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'CityFuelEfficiency',
				headline: 'MexicoCityFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'HighwayFuelEfficiency',
				headline: 'MexicoHighwayFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedEfficiency',
				headline: 'MexicoObservedCombinedEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
		],
		Gas: [
			{
				key: 'CityEmissions',
				headline: 'MexicoCityEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'HighwayEmissions',
				headline: 'MexicoHighwayEmissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedCO2Emissions',
				headline: 'MexicoObservedCombinedCO2Emissions',
				unit: 'WltpMexicoEmissionUnit',
				digits: 1,
			},
			{
				key: 'CityFuelEfficiency',
				headline: 'MexicoCityFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'HighwayFuelEfficiency',
				headline: 'MexicoHighwayFuelEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
			{
				key: 'ObservedCombinedEfficiency',
				headline: 'MexicoObservedCombinedEfficiency',
				unit: 'WltpMexicoEfficiencyUnit',
				digits: 1,
			},
		],
	},
};

export const consumptionList: {
    wltp?: ConsumptionListType;
} =
    setup?.market?.name === 'MEX'
        ? consumptionListMexico
        : {
              wltp: {
                  Electric: [
                      {
                          key: 'wltp_consumption_low',
                          headline: 'WltpFuelConsumptionLow',
                          unit: 'ElectricConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_medium',
                          headline: 'WltpFuelConsumptionMedium',
                          unit: 'ElectricConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_high',
                          headline: 'WltpFuelConsumptionHigh',
                          unit: 'ElectricConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_extra_high',
                          headline: 'WltpFuelConsumptionExtraHigh',
                          unit: 'ElectricConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_combined',
                          headline: 'WltpElectricConsumptionCombined',
                          unit: 'ElectricConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_co2_combined',
                          headline: 'WltpCO2Combined',
                          unit: 'CO2ConsumptionUnit',
                          disclaimer: 'consumption.carbon-dioxide-emissions',
                      },
                      {
                          key: 'wltp_energy_efficiency',
                          headline: 'WltpEnergyEfficiency',
                          unit: '',
                          hideIfEmpty: true,
                          efficiency: true,
                      },
                      {
                          key: 'wltp_range_combined',
                          headline: 'WltpElectricalRangeCombined',
                          unit: 'LengthUnit',
                          round: true,
                      },
                      {
                          key: 'wltp_range_city',
                          headline: 'WltpElectricalRangeUrban',
                          unit: 'LengthUnit',
                          round: true,
                      },
                  ],
                  HybridPetrol: [
                      {
                          key: 'wltp_consumption_low',
                          headline: 'WltpFuelConsumptionLow',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_medium',
                          headline: 'WltpFuelConsumptionMedium',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_high',
                          headline: 'WltpFuelConsumptionHigh',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_extra_high',
                          headline: 'WltpFuelConsumptionExtraHigh',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_combined',
                          headline: 'WltpFuelConsumptionCombined',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_electric_combined',
                          headline: 'WltpElectricConsumptionCombined',
                          unit: 'ElectricConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_co2_combined',
                          headline: 'WltpCO2Combined',
                          unit: 'CO2ConsumptionUnit',
                          disclaimer: 'consumption.carbon-dioxide-emissions',
                      },
                      {
                          key: 'wltp_energy_efficiency',
                          headline: 'Efficiency',
                          unit: '',
                          hideIfEmpty: true,
                          efficiency: true,
                      },
                      {
                          key: 'wltp_range_combined',
                          headline: 'WltpElectricalRangeCombined',
                          unit: 'LengthUnit',
                          round: true,
                      },
                      {
                          key: 'wltp_range_city',
                          headline: 'WltpElectricalRangeUrban',
                          unit: 'LengthUnit',
                          round: true,
                      },
                  ],
                  Diesel: [
                      {
                          key: 'wltp_consumption_low',
                          headline: 'WltpFuelConsumptionLow',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_medium',
                          headline: 'WltpFuelConsumptionMedium',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_high',
                          headline: 'WltpFuelConsumptionHigh',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_extra_high',
                          headline: 'WltpFuelConsumptionExtraHigh',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_combined',
                          headline: 'WltpFuelConsumptionCombined',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_co2_combined',
                          headline: 'WltpCO2Combined',
                          unit: 'CO2ConsumptionUnit',
                          disclaimer: 'consumption.carbon-dioxide-emissions',
                      },
                      {
                          key: 'wltp_energy_efficiency',
                          headline: 'Efficiency',
                          unit: '',
                          hideIfEmpty: true,
                          efficiency: true,
                      },
                  ],
                  Petrol: [
                      {
                          key: 'wltp_consumption_low',
                          headline: 'WltpFuelConsumptionLow',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_medium',
                          headline: 'WltpFuelConsumptionMedium',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_high',
                          headline: 'WltpFuelConsumptionHigh',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_extra_high',
                          headline: 'WltpFuelConsumptionExtraHigh',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_combined',
                          headline: 'WltpFuelConsumptionCombined',
                          unit: 'FuelConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_co2_combined',
                          headline: 'WltpCO2Combined',
                          unit: 'CO2ConsumptionUnit',
                          disclaimer: 'consumption.carbon-dioxide-emissions',
                      },
                      {
                          key: 'wltp_energy_efficiency',
                          headline: 'Efficiency',
                          unit: '',
                          hideIfEmpty: true,
                          efficiency: true,
                      },
                  ],
                  Gas: [
                      {
                          key: 'wltp_consumption_low',
                          headline: 'WltpFuelConsumptionLow',
                          unit: 'GasConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_medium',
                          headline: 'WltpFuelConsumptionMedium',
                          unit: 'GasConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_high',
                          headline: 'WltpFuelConsumptionHigh',
                          unit: 'GasConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_extra_high',
                          headline: 'WltpFuelConsumptionExtraHigh',
                          unit: 'GasConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_consumption_combined',
                          headline: 'WltpFuelConsumptionCombined',
                          unit: 'GasConsumptionUnit',
                          digits: 1,
                      },
                      {
                          key: 'wltp_co2_combined',
                          headline: 'WltpCO2Combined',
                          unit: 'CO2ConsumptionUnit',
                          disclaimer: 'consumption.carbon-dioxide-emissions',
                      },
                      {
                          key: 'wltp_energy_efficiency',
                          headline: 'Efficiency',
                          unit: '',
                          hideIfEmpty: true,
                          efficiency: true,
                      },
                  ],
              },

          };

export function disclaimerTagsInConsumptionLists(
	engineType: keyof ConsumptionListType
) {
    const disclaimerTags: Array<string> = [];
    if (
        consumptionList.wltp
    ) {
        const wltpConsumptions = consumptionList.wltp[engineType];

        if (
            wltpConsumptions
        ) {
            [
                ...wltpConsumptions,
            ].forEach(consumption => {
                if (
                    consumption.disclaimer &&
                    !disclaimerTags.includes(consumption.disclaimer)
                ) {
                    disclaimerTags.push(consumption.disclaimer);
                }
            });
        }
        return disclaimerTags;
    } else {
        return [];
    }
}

export function getConsumptionValue(
    c: ConsumptionElementType | undefined,
    vehicleDataModel: SSPContentFieldAccessor
) {
    if (!c) return '-';
    const value =
        c.overwrite !== undefined && c.overwrite !== null
            ? c.overwrite
            : vehicleDataModel.float(c.key, NaN);
    return c.efficiency && vehicleDataModel.str(c.key) !== 'none'
        ? vehicleDataModel.str(c.key, '-')
        : getNumberString(
              c.round ? Math.round(value) : value,
              i18next.language,
              i18next.t(c.unit),
              c.digits
          );
}
