import { STORAGE_KEY } from '../ActivityLogger/util';

export function checkForUpdates(
	url: string,
	device: string,
	fetchOptions: any,
	brand: string,
	label?: boolean
) {
	const checkURL = `${url}/module/content/bpca/depot/terminals/${device}/index.csv.hash`;
	let oldHash = '';
	let oldBuildInfo = localStorage.getItem('build-info');
	if (fetchOptions && fetchOptions.headers) {
		fetchOptions.headers['Service-Worker'] = 'false';
		fetchOptions.headers['Cache-Control'] = 'max-age=0';
	}
	const localStorageKey = 'build-info' + (label ? '-label' : '');

	const reload = () => {
		console.warn('Update available!');

		if (!label && navigator.onLine) {
			caches.keys().then(keyList =>
				Promise.all(
					keyList.map(key => {
						if (key.includes(brand)) caches.delete(key);
					})
				)
			);
			location.reload();
		}
	};

	const fetchHash = () => {
		fetch(checkURL, fetchOptions)
			.then(response => {
				if (response.status === 204 || response.status === 711)
					return oldHash;
				return response.text();
			})
			.then(hash => {
				if (oldHash === '') {
					oldHash = hash;
				} else if (hash !== oldHash) {
					localStorage.setItem('update-available', 'true');
					reload();
				}
			})
			.catch(e => console.warn(e));
		fetch('./build-info.json', {
			headers: {
				'Service-Worker': 'false',
				'Cache-Control': 'max-age=0',
			},
		})
			.then(r => r.json())
			.then(newBuildInfo => {
				if (
					oldBuildInfo &&
					new Date(newBuildInfo.timestamp) >
						new Date(JSON.parse(oldBuildInfo).timestamp)
				) {
					localStorage.setItem(
						localStorageKey,
						JSON.stringify(newBuildInfo)
					);
					reload();
				} else if (!oldBuildInfo) {
					localStorage.setItem(
						localStorageKey,
						JSON.stringify(newBuildInfo)
					);
				}
			})
			.catch(e => console.warn(e));
	};
	if (!label) fetchHash();
	setInterval(
		() => {
			if (label) {
				if (localStorage.getItem('update-available') === 'true') {
					localStorage.removeItem('update-available');
					reload();
				}
			} else {
				if (sessionStorage.getItem(STORAGE_KEY) === null) fetchHash();
			}
		},
		0.5 * 60000 * (label ? 0.1 : 1)
	); // 0.5min todo zurück zu 5min
}
