import { useContext } from 'react';
import { SspProviderContext } from '../../index';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';

export function getVideos() {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const mediaLib = contentModel
		.ref('salesgroupref')
		.asModel.ref('medialibrary');

	const mediaLibVideos = mediaLib.asModel
		.reflist('videos')
		.filter(item => item.asModel.bool('marketable', true));

	function getVideosFromEquipItems(equipItem: string): any[] {
		const videos: any[] = [];
		const equipItemRefs = contentModel.reflist(equipItem);

		equipItemRefs.forEach(equipItemRef => {
			if (equipItemRef.asModel.bool('marketable')) {
				const assets = equipItemRef.asModel.reflist('assets');

				assets.forEach(item => {
					if (item.contentType === 'assetVideo') {
						videos.push(item);
					}
				});
			}
		});
		return videos;
	}

	const highlightVideos = getVideosFromEquipItems('highlight_equip');
	const specialVideos = getVideosFromEquipItems('special_equip');
	const serieVideos = getVideosFromEquipItems('serie_equip');

	const videos = [
		...mediaLibVideos,
		...highlightVideos,
		...specialVideos,
		...serieVideos,
	];

	function getUniqueVideos(videos: any[]) {
		const uniqueVideos = new Map();

		videos.forEach(video => {
			uniqueVideos.set(video.uid, video);
		});

		return Array.from(uniqueVideos.values());
	}

	return getUniqueVideos(videos);
}
