import React from 'react';
import { CarouselProps } from './interfaces';
import NormalCarousel from './NormalCarousel';

export default function Carousel(props: CarouselProps) {
	const { className, children, currentIndex, ...rest } = props;

	if (
		new URLSearchParams(window.location.search).get(
			'prefers-reduced-motion'
		) === 'true'
	) {
		return (
			<div className={`carousel-slider-root ${className}`}>
				{currentIndex !== undefined && currentIndex !== null
					? children[currentIndex]
					: null}
			</div>
		);
	}

	return <NormalCarousel {...props}>{children}</NormalCarousel>;
}
