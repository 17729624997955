import * as PDFJS from 'pdfjs-dist/legacy/build/pdf';
import 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { getSetup } from '../../Functions/getSetup';

const deviceType = new URLSearchParams(window.location.search).get(
    'device-type'
);

export function canvasFromPDF(url: string) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            PDFJS.getDocument({
                url: URL.createObjectURL(blob),
                isEvalSupported: false,
            }).promise.then(
                pdf => {
                    const canvasDiv = document.getElementById('canvas');

                    if (canvasDiv && canvasDiv.innerHTML === '') {
                        const height = Math.max(
                            window.innerHeight,
                            window.innerWidth
                        );
                        const width = Math.min(
                            window.innerHeight,
                            window.innerWidth
                        );

                        pdf.getPage(1).then(page => {
                            // Calculate page dimensions to fit window
                            const vp1 = page.getViewport({ scale: 1 });
                            const ratio = vp1.height / vp1.width;
                            const maxHeight = Math.min(height, width * ratio);
                            const maxWidth = Math.min(width, height / ratio);
                            const setup = getSetup();

                            const scale =
                                Math.min(
                                    maxHeight / vp1.height,
                                    maxWidth / vp1.width
                                ) *
                                (setup.market.name === 'DEU' &&
                                deviceType === 'Umdasch-2_no-Soundbar'
                                    ? 0.918
                                    : setup.market.name === 'DEU' &&
                                      deviceType === 'Umdasch_no-Soundbar'
                                    ? 1.09
                                    : 1);

                            const viewport = page.getViewport({ scale: scale });

                            const canvas = document.createElement('canvas');
                            canvasDiv.appendChild(canvas);

                            // Prepare canvas using PDF page dimensions
                            const context = canvas.getContext('2d');
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;

                            // Render PDF page into canvas context
                            const renderContext = {
                                canvasContext: context,
                                viewport: viewport,
                            };

                            const renderTask = page.render(
                                renderContext as any
                            );
                            renderTask.promise.then(function () {
                                canvas.toDataURL('image/png');
                            });
                        });
                    }
                },
                error => console.error(error)
            );
        });
}
