export type DataLabelElementType = {
	fuel: {
		combined?: string;
		urban?: string;
		extraUrban?: string;
		unit: string;
	};
	electricity: {
		combined?: string;
	};
	cost: {
		fuel?: string;
		fuelPrice?: string;
		fuelCostTotal?: string;
		fuelPriceElectrical?: string;
		fuelCostElectricalTotal?: string;
	};
};

export type DataLabelType = {
	Electric: DataLabelElementType;
	HybridPetrol: DataLabelElementType;
	Petrol: DataLabelElementType;
	Diesel: DataLabelElementType;
	Gas: DataLabelElementType;
};

export const engineTypes: Array<string> = [
	'Electric',
	'HybridPetrol',
	'Diesel',
	'Petrol',
	'Gas',
];

export const dataLabel: DataLabelType = {
	Electric: {
		fuel: {
			unit: 'ENVKV_FuelConsumptionPetrolDieselUnit',
		},
		electricity: {
			combined: 'nedc_consumption_combined',
		},
		cost: {
			fuelPriceElectrical: 'fuel_price_electrical',
			fuelCostElectricalTotal: 'fuel_cost_electrical_total',
		},
	},
	HybridPetrol: {
		fuel: {
			combined: 'nedc_consumption_combined',
			urban: 'nedc_consumption_combined_urban',
			extraUrban: 'nedc_consumption_combined_extra_urban',
			unit: 'ENVKV_FuelConsumptionPetrolDieselUnit',
		},
		electricity: {
			combined: 'nedc_consumption_electric_combined',
		},
		cost: {
			fuel: 'fuel',
			fuelPrice: 'fuel_price',
			fuelCostTotal: 'fuel_cost_total',
			fuelPriceElectrical: 'fuel_price_electrical',
			fuelCostElectricalTotal: 'fuel_cost_electrical_total',
		},
	},
	Petrol: {
		fuel: {
			combined: 'nedc_consumption_combined',
			urban: 'nedc_consumption_combined_urban',
			extraUrban: 'nedc_consumption_combined_extra_urban',
			unit: 'ENVKV_FuelConsumptionPetrolDieselUnit',
		},
		electricity: {},
		cost: {
			fuel: 'fuel',
			fuelPrice: 'fuel_price',
			fuelCostTotal: 'fuel_cost_total',
		},
	},
	Diesel: {
		fuel: {
			combined: 'nedc_consumption_combined',
			urban: 'nedc_consumption_combined_urban',
			extraUrban: 'nedc_consumption_combined_extra_urban',
			unit: 'ENVKV_FuelConsumptionPetrolDieselUnit',
		},
		electricity: {},
		cost: {
			fuel: 'fuel',
			fuelPrice: 'fuel_price',
			fuelCostTotal: 'fuel_cost_total',
		},
	},
	Gas: {
		fuel: {
			combined: 'nedc_consumption_combined',
			urban: 'nedc_consumption_combined_urban',
			extraUrban: 'nedc_consumption_combined_extra_urban',
			unit: 'ENVKV_FuelConsumptionGasUnit',
		},
		electricity: {},
		cost: {
			fuel: 'fuel',
			fuelPrice: 'fuel_price',
			fuelCostTotal: 'fuel_cost_total',
		},
	},
};
