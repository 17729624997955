export function normalFetch(url: string, auth: string): Promise<Response> {
	return auth
		? fetch(url, {
				method: 'GET',
				headers: {
					Authorization: 'Basic ' + auth,
				},
			})
		: fetch(url);
}

// custom fetch with XMLHttpRequest to avoid timeout
export function customFetch(
	url: string,
	auth: string,
	type?: string
): Promise<Response> {
	if (window.navigator.onLine) {
		return new Promise(function (resolve, reject) {
			const xml = new XMLHttpRequest();
			xml.open('GET', url, true);
			xml.setRequestHeader('Service-Worker', 'false');
			if (auth) {
				xml.setRequestHeader('Authorization', 'Basic ' + auth);
			}
			xml.responseType = 'blob';
			xml.onload = function () {
				if (xml.status > 599) {
					resolve(normalFetch(url, auth));
				} else {
					var blob = xml.response;
					if (type) {
						blob = new Blob([blob], { type: type });
					}
					var myResponse = new Response(
						xml.status === 200 ? blob : null,
						{
							status: xml.status,
							statusText: xml.statusText,
						}
					);
					resolve(myResponse);
				}
			};
			xml.onerror = function () {
				resolve(normalFetch(url, auth));
			};
			xml.send();
		});
	}
	return normalFetch(url, auth);
}
