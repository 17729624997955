import React from 'react';
import './styles.scss';
import BackToSetup from '../BackToSetup';
import { getSetup } from '../../Functions/getSetup';

export default function Loading(props: { text?: string }): JSX.Element {
	const setup = getSetup();
	const orientationFromParams = new URLSearchParams(
		window.location.search
	).get('orientation');
	if (orientationFromParams)
		localStorage.setItem('orientation', orientationFromParams);
	const orientation =
		orientationFromParams || localStorage.getItem('orientation') || '';
	return (
		<div className='loading-page' id='#loading-page'>
			<div className={`wrapper ${orientation}`}>
				<div className='loading-spinner' />
				{props.text && <p className='caching-progress'>{props.text}</p>}
				{setup && setup.brand && <BackToSetup brand={setup.brand} />}
			</div>
		</div>
	);
}
