import { sortAlphabeticallyByKey } from './EquipListManagement';
import { useContext } from 'react';
import { SspProviderContext } from '../../index';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';

export default function getWheelAndColorTiles(
	type: string,
	familyName?: string
): SSPContentVO[] {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const salesGroupModel = contentModel.ref('salesgroupref').asModel;
	const prString = String(contentModel.ref('prString').uid);
	const usedUidsWheels = salesGroupModel
		.reflist('completeWheels')
		.filter(wheel => prString.includes(wheel.asModel.str('number')))
		.map(wheel => wheel.uid);

	const sortName = sortAlphabeticallyByKey(
		salesGroupModel
			.reflist(type)
			.filter(item =>
				type === 'completeWheels'
					? item.asModel.bool('marketable', true) &&
						item.asModel.str('family') === familyName
					: item.asModel.bool('marketable', true)
			),
		'layertitle'
	);

	if (type === 'extColors') {
		return [
			...sortName.filter(
				w =>
					contentModel.ref('color_out_image').uid.includes(w.uid) &&
					w.asModel.has('assets')
			),
			...sortAlphabeticallyByKey(
				sortName.filter(
					w =>
						!contentModel
							.ref('color_out_image')
							.uid.includes(w.uid) && w.asModel.has('assets')
				),
				'equipcategory'
			),
			...sortAlphabeticallyByKey(
				sortName.filter(w => !w.asModel.has('assets')),
				'equipcategory'
			),
		];
	}

	if (type === 'intColors') {
		return [
			...sortName.filter(
				w =>
					contentModel.ref('color_in_image').uid.includes(w.uid) &&
					w.asModel.has('assets')
			),
			...sortAlphabeticallyByKey(
				sortName.filter(
					w =>
						!contentModel
							.ref('color_in_image')
							.uid.includes(w.uid) && w.asModel.has('assets')
				),
				'equipcategory'
			),
			...sortAlphabeticallyByKey(
				sortName.filter(w => !w.asModel.has('assets')),
				'equipcategory'
			),
		];
	}

	if (type === 'completeWheels') {
		return [
			...sortName.filter(
				w => usedUidsWheels.includes(w.uid) && w.asModel.has('assets')
			),
			...sortName.filter(
				w => !usedUidsWheels.includes(w.uid) && w.asModel.has('assets')
			),
			...sortName.filter(w => !w.asModel.has('assets')),
		];
	}

	return [];
}
