import { getSetup } from './getSetup';
import React from "react";

export function videoSrcFetch(
	src: string,
	videoRef: React.MutableRefObject<any>,
	play?: boolean,
	then?: Function,
	error?: Function
) {
	const setup = getSetup();
	let fetchOptions: RequestInit | undefined = {
		method: 'GET',
		headers: {},
	};
	if (setup) {
		fetchOptions = {
			method: 'GET',
			headers: {
				Authorization: 'Basic ' + window.btoa(`ssp:${setup.password}`),
			},
		};
	}

	fetch(src, fetchOptions)
		.then(res => {
			if (res.status < 400) {
				res.blob().then(blob => {
					if (videoRef.current) {
						videoRef.current.src = URL.createObjectURL(blob);
						if (then) then();
						if (play === true) videoRef.current.play();
					}
				});
			} else {
				if (error) error();
			}
		})
		.catch(e => {
			if (error) error();
		});
}
