import React, { useContext, useEffect, useState } from 'react';
import Loading from '../Components/Loading';
import SspProviderContext from '../SspProvider/context';
import i18next from 'i18next';
import ErrorPage from '../Components/ErrorPage';

function TranslationProvider(props: {
	children: React.ReactNode;
	setupLink: string;
	projectBrand: string;
	projectAll?: string;
	projectLabel?: string;
	projectSetup?: string;
}): JSX.Element {
	const store = useContext(SspProviderContext);
	const [loaded, setLoaded] = useState<Array<string>>([]);
	const [error, setError] = useState<string | null>(null);

	const getJSON = (local: string, project: string | undefined) => {
		if (!project) return;
		const lStorage = JSON.parse(
			localStorage.getItem(`${local}-${project}`) || '{}'
		);
		const url = `https://api.phrase.com/v2/projects/${project}/locales/${local}/download?file_format=i18next`;

		const headers = {
			Authorization:
				'token ' +
				(import.meta.env.VITE_REACT_APP_PHRASE_TOKEN as string),
			...(lStorage.time ? { 'If-Modified-Since': lStorage.time } : {}),
		};

		return fetch(url, { headers })
			.then(response => {
				if (!response.ok) {
					return { localStorage: true };
				}
				return response.json();
			})
			.then(json => {
				if (json.localStorage === true && lStorage.response) {
					json = lStorage.response;
				} else {
					localStorage.setItem(
						`${local}-${project}`,
						JSON.stringify({
							time: new Date(Date.now()).toUTCString(),
							response: json,
						})
					);
				}

				i18next.addResourceBundle(
					local,
					'translation',
					json,
					true,
					true
				);
				return;
			})
			.catch(() => {
				if (lStorage.response) {
					i18next.addResourceBundle(
						local,
						'translation',
						lStorage.response,
						true,
						true
					);
				} else {
					setError(`Error fetching language ${local}!`);
				}
			});
	};

	async function downloadLanguages() {
		for await (const local of store.state.content?.languages || []) {
			await Promise.all([
				getJSON(
					local,
					props.projectAll || 'd1a94f0be71d05a23012812c2d0c49c5'
				),
				getJSON(local, props.projectBrand),
				getJSON(local, props.projectLabel),
				getJSON(local, props.projectSetup),
			])
				.then(results => {
					setLoaded(prevState => [...prevState, local]);
				})
				.catch(error => {
					setError('Error fetching languages!');
				});
		}
	}

	useEffect(() => {
		if (store.state.content) {
			i18next.changeLanguage(store.state.content.languages[0]);
			document.documentElement.setAttribute(
				'lang',
				store.state.content.languages[0]
			);
			downloadLanguages();
		}
	}, [store.state.content]);

	if (
		store.state.content === null ||
		loaded.length < store.state.content.languages.length
	) {
		return <Loading />;
	} else if (error) {
		return <ErrorPage msg={error} link={props.setupLink} />;
	}

	return <>{props.children}</>;
}
export default TranslationProvider;
